import { defineAsyncComponent } from 'vue';

// Sizer
export { default as OSizerPanel } from './Sizer.SizerPanel.vue';
export { default as SizerControl, type ISizerStorageItem } from './Sizer.Sizer.ts';

// Inputs
export { default as ODatePicker } from './Inputs.DatePicker.vue';
export { default as OContentEditable } from './Inputs.ContentEditable.vue';
export { default as OInputMask } from './Inputs.InputMask.vue';
export { default as OTextArea } from './Inputs.TextArea.vue';
export { default as OSelect } from './Inputs.Select.vue';
export { default as OSearch } from './Inputs.Search.vue';

// Input Editors (used by grid)
export { default as OTextEditor } from './Inputs.Editors.TextEditor.vue';
export { default as ONumberEditor } from './Inputs.Editors.NumberEditor.vue';
export { default as OBitEditor } from './Inputs.Editors.BitEditor.vue';
export { default as ODateEditor } from './Inputs.Editors.DateEditor.vue';

// Containers
export { default as OColContainer } from './Containers.ColContainer.vue';
export { default as ORowContainer } from './Containers.RowContainer.vue';
// export { default as ORowContainerTest } from './Containers.RowContainerTest.vue';


// Tabs
export { default as OTabs } from './Tabs.Tabs.vue';
export { default as OTab } from './Tabs.Tab.vue';
export { default as TabControl } from './Tabs.TabControl.ts';


export { default as ODropdown } from './Dropdown.vue';
export { default as OModal } from './Modal.vue';

export { default as OIframe } from './Iframes.Iframe.vue';
export { default as ODetailsIframe } from './Iframes.DetailsIframe.vue';


// MediaQuery stuff
export { default as OMatchMedia } from './Helpers.MatchMedia.vue';
export { default as OMediaQueryProvider } from './Helpers.MediaQueryProvider.vue';
export { default as OMatchContainerSizeQuery } from './Helpers.MatchContainerSizeQuery.vue';
export { default as MediaQueryManager } from './Helpers.MediaQueryManager.ts';

// Form stuff (no dataobject/dataitem refrences)
export { default as OToolbarAction } from './Form.ToolbarAction.vue';
export { default as OForm } from './Form.Form.vue';
export { default as OFormControl } from './Form.FormControl.vue';
export { OLink, OCheck, OBodyWrapper } from './renderers.jsx';

export { default as Overlay } from './Overlay.vue';

export { default as OButton } from "./Button.vue";
export { default as OSwitch } from "./Switch.vue";


const ODialog = defineAsyncComponent(() => import('./Dialog.vue'));
const OJSONEditor = defineAsyncComponent(() => import('./Inputs.JSONEditor.vue'));

const OToolbar = defineAsyncComponent(() => import('./Form.Toolbar.vue'));

// Sidepanel
const OSidePanel = defineAsyncComponent(() => import('./SidePanel.SidePanel.vue'));
const OSidePanelTabs = defineAsyncComponent(() => import('./SidePanel.SidePanelNavTabs.vue'));

export { ODialog, OJSONEditor, OToolbar, OSidePanel, OSidePanelTabs };

// ActionList
export { default as OActionList } from "./ActionList.ActionList.vue";
export { default as OActionItem } from "./ActionList.ActionItem.vue";


// Collapse
// export { default as OCollapse } from "./Collapse.vue";
export const OCollapse = defineAsyncComponent(() => import('./Collapse.vue'));

